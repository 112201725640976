import React from 'react';
import { useRouter } from 'next/router';
import useUser from '../lib/useUser';
import Head from 'next/head';
import fetchJson from "lib/fetchJson";
import {client_system_uri} from "../config";

/**
 ironSessionでセッションを作る。
 homeで作る必要はないが、localsessionを使えないブラウザならhomeで止まることになり効果はある
 */
export default function Home() {

  const { user, mutateUser } = useUser( {
    redirectTo: '/mail',
    redirectIfFound: true
  } );
  console.log( "USER:",user );

  const login = async () => {
    let router = useRouter();
    await router.push( '/login/' );
  };

  let pageDescription = "USOMは 使い捨てメールサービスをポッポコ創出するアプリです。迷惑メール、広告メール、ハッキング、攻撃ロボットをなくしましょう。あなたの本当のメールボックスをクリーンで安全に保ちます。USOM Mailでは、一時的な匿名の使い捨てメールアドレスを利用することができます。";

  return (
    <>
      <Head>
        <title>{ "USOM " }</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
        <meta name="description" content={ pageDescription }/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content={ "/og_cover500.png" }/>
      </Head>

      <section className="container_head">
        <button className={ "button_simple" } onClick={ login }> USOM</button>
      </section>
    </> );
};


/**
 // Static 部分SSRの検証：Cryptoの価格をとってくるだけで、何も意味を持たせていない
 // アカウントは自分のものを使っている。 プランはfreefree
 // https://pro.coinmarketcap.com/account/
 // d6c9fd39-81f5-4488-82e8-6a7da830c050
 **/
export async function getStaticProps() {
  // https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest
  const res = await fetch( 'https://sandbox-api.coinmarketcap.com/v1/cryptocurrency/listings/latest', {
    headers: {
      'X-CMC_PRO_API_KEY': 'b54bcf4d-1bca-4e8e-9a24-22ff2c3d462c'
    }
  } );
  const cryptocurrency = await res.json();
  return {
    props: { cryptocurrency },
    revalidate: 10
  };
}

// export const getServerSideProps = withIronSessionSsr(async function ({ req, res, }) {
//     const user = req.session.user;
//
//     if (user === undefined) {
//       res.setHeader("location", "/login");
//       res.statusCode = 302;
//       res.end();
//       return {
//         props: {
//           user: { isLoggedIn: false, login: "", avatarUrl: "" },
//         },
//       };
//     }
//
//     return {
//       props: { user: req.session.user },
//     };
//   }
//  );
